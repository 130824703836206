import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { isPresent } from './is-present.validator';

export const currenciesAmountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }


  if (control.value) {
    const isValid = control.value.every(x => x.amount);

    if (isValid) {
      return null;
    }
  }

  return { 'invalid-currency-amount': true };

};
