import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IGeoInfo } from '@bs/models/me/account';
import { isPresent } from './is-present.validator';

/**
 * validator for the cva of GeoInfosComponent fields which checks each of the fields contains value
 *
 * @param hideProvinces {boolean} validating the province field value if visible
 */
export function geoInfosValidator(hideProvinces: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isPresent(Validators.required(control))) {
      return null;
    }

    const values: Array<IGeoInfo> = [].concat(control.value);

    let isValid = values.every(v => v && v.country && v.region && v.city && v.address && v.address.length <= 64 && v.zipCode && v.zipCode.length <= 16);

    if (!hideProvinces) {
      isValid = isValid && values.every(v => v && v.province);
    }

    return isValid ? null : { 'invalid-geoInfos': true };
  };
}
