import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IPersonBirthPlace } from '@bs/models/me/me';
import { isPresent } from './is-present.validator';

/**
 * validator for the cva of BirthPlaceComponent fields which checks each of the fields contains value
 *
 * see {@link IPersonBirthPlace} field types model
 *
 * @param control
 */
export const birthPlaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors => {
  if (isPresent(Validators.required(control))) {
    return null;
  }

  const v: IPersonBirthPlace = control.value;

  const isValid = v && v.country && v.region && v.city && v.province;

  return isValid ? null : { 'invalid-birthPlace': true };
};
