import { KeyValue } from '@angular/common';
import { ICity, ICountry, IProvince, IRegion } from '../common/country';
import { CurrencyConversion, ICurrency } from '../common/currency';
import { IdName, idNames, keyValues } from '../common/id-name';
import { NetworkTypes } from './network-types';
import { registrationMode } from './registration';

export enum DeliveryType {
  // Telephone = 1,
  Mobile = 2,
  Email
  /*,
  Skype,
  URL,
  Fax*/
}

export namespace DeliveryType {
  export function values(): Array<KeyValue<number, string>> {
    return keyValues(DeliveryType);
  }

  export function names(): Array<IdName<string>> {
    return idNames(DeliveryType);
  }
}

export interface IDelivery {
  id?: number;
  delivery: string;
  typeId: DeliveryType;
  isConfirmed?: boolean;
  isPrimary: boolean;
}

export enum GeoInfoType {
  /*Birth = 1,
  Home,
  Residence,
  Headquarters,
  RegisteredOffice*/
  Residence = 3,
  Headquarters
}

export namespace GeoInfoType {
  export function values(): Array<KeyValue<number, string>> {
    return keyValues(GeoInfoType);
  }

  export function names(): Array<IdName<string>> {
    return idNames(GeoInfoType);
  }
}

export interface IGeoInfo {
  id: number;
  address?: string;
  country: ICountry;
  region: IRegion;
  typeId: GeoInfoType;
  city?: ICity;
  latitude?: number;
  longitude?: number;
  province?: IProvince;
  zipCode?: string;
}

interface IPermissionThreshold {
  value: number;
  thresholdId: number;
}

export interface IPermissions {
  booleans: Array<number>;
  thresholds: Array<IPermissionThreshold>;
}

export interface IWallet {
  id: number;
  name: string;
  balance: IBalance;
}

export interface IBalance extends CurrencyConversion {
  available: number;
  bonus: number;
  deposit: number;
  withdrawable: number;
  overdraft: number;
  bonuses?: IBalanceBonus[];
}

export interface IBalanceBonus {
  available: number;
  bonusModelId: number;
  modelId?: number;
  gameTypeId: number
  statusId: number
  creationDate: string;
  startDate: string;
  endDate: string;
}

export interface IShopLocation {
  id: number;
  name: string;
  city: string;
  address: string;
}

export interface IBookmaker {
  id: number;
  name: string;
  url: string;
  logo: string;
  currencies?: Array<ICurrency>;
  countries?: Array<ICountry>;
  languages?: Array<any>;
  webApps?: Array<IdName<string>>;
  registrations?: [
    {
      networkTypeId: NetworkTypes,
      mode: registrationMode
    }
  ]
}

export interface IAccountsRequestFilter {
  accountId?: number;
  bookmakers?: Array<number>;
  typeId: number;
  usernameSearchCriteriaId?: number;
  username?: string;
  parentId?: number;
  email?: string;
  lastname?: string;
  statusId?: number;
  fullNetwork?: boolean;
  from?: Date;
  to?: Date;
  p: number;
  pp: number;
  displayCurrencyId?: number;
  orderBy?: string;
  orderByDirection?: string;
}
